<template>
  <div class="allProducts">
    <div class="columns">
      <div class="column">
        <h1 class="title">
          All Products
        </h1>
      </div>
      <div class="column is-flex is-justify-content-end">
        <router-link to="/dashboard/products/create" class="button is-outlined is-primary">Create Product</router-link>
      </div>
    </div>

    <ProductTable :products="products"></ProductTable>
  </div>
</template>

<script>
import {mapGetters} from "vuex"
import ProductTable from "@/components/dashboard/products/ProductTable"

export default {
  name: 'All Products',
  data() {
    return {

    }
  },
  components: {
    ProductTable
  },
  computed: {
    ...mapGetters({
      products: "Products/getProducts"
    })
  },
  mounted: function() {
    this.$store.dispatch("Products/setProducts")
  }
}
</script>
