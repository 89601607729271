<template>
  <div class="singleProduct">
    <div class="productDetails" v-if="product">

      <div class="columns is-multiline">

        <div class="pageSection column is-full">
          <div class="columns">
            <div class="column is-flex is-align-items-center">
              <button class="button is-inverted is-info" style="margin-right: 25px;" @click="$router.push('/dashboard/products')">Back</button>
              <h1 class="is-size-3">Product Details</h1>
            </div>
            <div class="column is-flex is-justify-content-end">
              <div class="buttons">
                <div class="dropdown is-hoverable">
                  <div class="dropdown-trigger">
                    <button class="button" aria-haspopup="true" aria-controls="dropdown-menu">
                      <span>Options</span>
                      <span class="icon is-small">
                        <i class="fas fa-angle-down" aria-hidden="true"></i>
                      </span>
                    </button>
                  </div>
                  <div class="dropdown-menu" id="dropdown-menu" role="menu">
                    <div class="dropdown-content">
                      <router-link :to="'/dashboard/products/' + uid + '/edit'" class="dropdown-item">
                        Edit Product
                      </router-link>
                      <hr class="dropdown-divider">
                      <a @click="deleteProduct" class="dropdown-item has-text-danger">
                        Delete Product
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="pageSection column is-full">
          <div class="columns">
            <div class="column is-full">
              <div class="box">
                <div class="columns">
                  <div class="column">
                    <div class="field">
                      <label class="label">Name</label>
                      <span class="is-size-4">{{product.name || "-"}}</span>
                    </div>
                  </div>
                  <div class="column">
                    <div class="field">
                      <label class="label">SKU</label>
                      <span class="is-size-4">{{product.sku || "-"}}</span>
                    </div>
                  </div>
                  <div class="column">
                    <div class="field">
                      <label class="label">List Price</label>
                      <span class="is-size-4">{{product.listPrice || "-"}}</span>
                    </div>
                  </div>
                  <div class="column">
                    <div class="field">
                      <label class="label">Category</label>
                      <span class="is-size-4">{{_category || "-"}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="column is-full">
          <div class="columns">
            <div class="column is-2">
              <div class="box">
                <h2 class="is-size-5">Attributes</h2>
                <table class="table is-fullwidth">
                  <thead>
                    <th></th>
                    <th></th>
                  </thead>
                  <tbody v-if="product.attributes.length > 0">
                    <tr v-for="(attribute, i) in product.attributes" :key="i">
                      <td><label class="label">{{attribute.name}}</label></td>
                      <td>{{attribute.value || "-"}}</td>
                    </tr>
                  </tbody>
                  <span v-else>
                    No attributes listed
                  </span>
                </table>
              </div>
            </div>

            <div class="column is-one-fifth">
              <div class="box">
                <h2 class="is-size-5" style="margin-bottom: .5em;">Inventory</h2>
                <div class="columns">
                  <div class="column field">
                    <label class="label">
                      Aisle
                    </label>
                    <span class="is-size-4">{{product.aisle || "-"}}</span>
                  </div>
                  <div class="column field">
                    <label class="label">
                      Bin
                    </label>
                    <span class="is-size-4">{{product.bin || "-"}}</span>
                  </div>
                  <div class="column field">
                    <label class="label">
                      Quantity
                    </label>
                    <span class="is-size-4">{{product.quantity_on_hand || "-"}}</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="column">
              <div class="box">
                <h2 class="is-size-5" style="margin-bottom: .5em;">Supplier</h2>
                <div class="columns is-multiline">
                  <div class="column field">
                    <label class="label">
                      Supplier
                    </label>
                    <span class="is-size-4">{{_supplier || "-"}}</span>
                  </div>
                  <div class="column field">
                    <label class="label">
                      Supplier SKU
                    </label>
                    <span class="is-size-4">{{product.supplierSKU || "-"}}</span>
                  </div>
                  <div class="column field">
                    <label class="label">
                      Cost
                    </label>
                    <span class="is-size-4">{{product.cost || "-"}}</span>
                  </div>
                  <div class="column field">
                    <label class="label">
                      Lead Time
                    </label>
                    <span class="is-size-4">{{product.supplier_lead_time || "-"}}</span>
                  </div>
                  <div class="column is-full">
                    <span class="has-text-danger is-size-5">{{product.supplier_discontinued? 'Discontinued' : ''}}</span>
                    <span class="has-text-danger is-size-5">{{product.supplier_out_of_stock? 'Out of Stock' : ''}}</span>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div style="width: 100%; height: 100%;" v-else>
      <Loader :isActive="true"></Loader>
    </div>
  </div>
</template>

<script>
import Loader from "@/components/global/Loader"
import Products from "@/api/Products"
import {mapGetters} from "vuex"

export default {
  name: 'Single Product',
  data() {
    return {
      product: null
    }
  },
  methods: {
    setProduct(product) {
      console.log("PRODUCT", product)
      this.product = product
    },
    deleteProduct() {
      this.$store.dispatch("Products/deleteProduct", this.uid).then(res=>{
        this.$router.push("/dashboard/products")
      })
    }
  },
  computed: {
    uid() {
      return this.$route.params.uid
    },
    _supplier() {
      if(this.productSuppliers) {
        for(var i = 0; i < this.productSuppliers.length; i++) {
          if(this.productSuppliers[i].uid == this.product.supplier) {
            return this.productSuppliers[i].name
          }
        }
      }
    },
    _category() {
      if(this.productCategories) {
        for(var i = 0; i < this.productCategories.length; i++) {
          if(this.productCategories[i].uid == this.product.category) {
            return this.productCategories[i].name
          }
        }
      }
    },
    ...mapGetters({
      productSuppliers: "ProductSuppliers/getProductSuppliers",
      productCategories: "ProductCategories/getProductCategories"
    })
  },
  mounted: function() {
    Products.getOne(this.uid).then(this.setProduct)
    this.$store.dispatch("ProductSuppliers/setProductSuppliers")
    this.$store.dispatch("ProductCategories/setProductCategories")
  },
  components: {
    Loader
  }
}
</script>
<style scoped>
  .singleProduct {
    width: 100%;
    height: 100%;
  }

  .pageSection {
    margin-bottom: 2em;
  }

  .box {
    border: 1px solid #e0e0e0;
    height: 100%;
  }
</style>
