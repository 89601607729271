<template>
  <div class="newProduct" v-if="product">
    <h1 class="title">
      Edit Product
    </h1>
    <div class="pageSection columns">
      <div class="column is-full">
        <div class="box columns">
  
          <div class="field column">
            <label class="label">Name</label>
            <input type="text" class="input" v-model="product.name">
          </div>
 
          <div class="field column">
            <label class="label">SKU</label>
            <input type="text" class="input" v-model="product.sku">
          </div>
 
          <div class="field column">
            <label class="label">List Price</label>
            <input type="text" class="input" v-model="product.listPrice">
          </div>

          <div class="field column">
            <label class="label">Category</label>
            <div class="select is-fullwidth">
              <select v-model="product.category">
                <option :key="category.uid" v-for="category in _categories" :value="category.uid">{{category.name}}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="pageSection columns">
      <div class="column is-one-quarter">
        <div class="box">
          <div class="is-flex is-align-items-center">
            <h2 class="is-size-5">
              Attributes
            </h2>
            <button class="button is-inverted is-info" style="margin-left: auto; margin-top: 5px;" @click="newAttributeModalOpen = true">Add Attribute</button>
          </div>

          <span v-if="product.attributes.length == 0">This product has no attributes</span>
          <table class="table is-fullwidth">
            <thead>
              <th></th>
              <th></th>
            </thead>
            <tbody>
              <tr v-for="(attribute, i) in product.attributes" :key="i">
                <td>{{attribute.name}}</td>
                <td>{{attribute.value}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="column is-one-quarter">
        <div class="box">
          <h2 class="is-size-5" style="margin-bottom: 10px;">
            Inventory
          </h2>
    
          <div class="field">
            <label class="label">
              Aisle
            </label>
            <input class="input" type="text" v-model="product.aisle">
          </div>
          <div class="field">
            <label class="label">
              Bin
            </label>
            <input class="input" type="text" v-model="product.bin">
          </div>
          <div class="field">
            <label class="label">
              Quantity
            </label>
            <input class="input" type="text" v-model="product.quantity_on_hand">
          </div>
        </div>
      </div>

      <div class="column is-half">
        <div class="box">
        <div class="columns is-multiline  supplierInfo">
          <div class="column is-full">
            <h2 class="is-size-5">
              Supplier Information
            </h2>
          </div>
          <div class="column">
            <div class="field">
              <div class="is-flex is-align-items-center">
                <label class="label">Supplier </label>
                <small class="is-info" style="margin-left: auto; margin-bottom: 0.5em;" @click="newSupplierModalOpen = true">
                  <a href="#" >
                  Add Supplier
                  </a>
                </small>
              </div>
              <div class="select is-fullwidth">
                <select v-model="product.supplier">
                  <option disabled value="" style="display: none;"></option>
                  <option :value="supplier.uid" v-for="supplier in suppliers" v-html="supplier.name" :key="supplier.uid"></option>
                </select>
              </div>
            </div>
            <div class="field">
              <label class="label">Cost</label>
              <input type="text" class="input" v-model="product.cost">
            </div>
            
          </div>
          <div class="column">
            <div class="field">
              <label class="label">Supplier SKU</label>
              <input type="text" class="input" v-model="product.supplierSKU">
            </div>


            <div class="field">
              <label class="label">Lead Time</label>
              <input type="text" class="input" v-model="product.supplier_lead_time">
            </div>
          </div>
          <div class="column">
            <div class="field is-flex is-flex-direction-column">
              <label class="label">Additional Information</label>
              <label class="checkbox">
                <input disabled v-model="product.supplier_discontinued" type="checkbox">
                Discontinued
              </label>
              <label class="checkbox">
                <input v-model="product.supplier_out_of_stock" type="checkbox">
                Out of Stock
              </label>
            </div>
          </div>
        </div>
        </div>
      </div>

    </div>


    

    <div class="columns">
      <div class="column is-flex is-justify-content-end">
        <button class="button is-primary" @click="saveProduct" :disabled="!canSave">Save Changes</button>
      </div>
    </div>
    <NewSupplierModal :open="newSupplierModalOpen" @onClose="closeNewSupplierModal"></NewSupplierModal>
    <NewAttributeModal :open="newAttributeModalOpen" @onSubmit="addAttribute" @onClose="closeNewAttributeModal"></NewAttributeModal>

  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import NewSupplierModal from '@/components/dashboard/products/NewProductSupplierModal'
import NewAttributeModal from '@/components/dashboard/products/NewProductAttributeModal'
import Products from "@/api/Products"

export default {
  name: 'New Product',
  components: {
    NewSupplierModal,
    NewAttributeModal
  },
  data() {
    return {
      product: null,
      originalProduct: null
    }
  },
  methods: {
    closeNewAttributeModal() {
      this.newAttributeModalOpen = false
    },
    addAttribute(data) {
      var name = data.name
      var value = data.value

      this.attributes.push({
        name: name,
        value: value
      })

      this.newAttributeModalOpen = false
      
    },
    closeNewSupplierModal(res) {
      if(res && res.uid) {
        this.supplier = res.uid
      }
      console.log(res)
      this.newSupplierModalOpen = false
    },
    saveProduct() {
      this.$store.dispatch("Products/updateProduct", this.product).then(this.redirect)
    },
    redirect(product) {
      if(product.uid) {
        this.$router.push("/dashboard/products/" + product.uid)
      } else {
        this.$toast.create("Unable to create product")
      }
    },
    setProduct(product) {
      this.originalProduct = Object.assign({}, product)
      this.product = product
    },
    hasChanges(x, y) {
      const ok = Object.keys, tx = typeof x, ty = typeof y;
      return x && y && tx === 'object' && tx === ty ? (
        ok(x).length === ok(y).length &&
          ok(x).every(key => this.hasChanges(x[key], y[key]))
      ) : (x === y);
    }
  },
  computed: {
    canSave() {
      return !this.hasChanges(this.product, this.originalProduct)
    },
    suppliers() {
      if(!this._suppliers) {
        return []
      }
      return this._suppliers.sort((a, b) => { a.name.localeCompare(b.name) })
    },
    uid() {
      return this.$route.params.uid
    },
    ...mapGetters({
      _suppliers: "ProductSuppliers/getProductSuppliers",
      _categories: "ProductCategories/getProductCategories"
    })
  },
  watch: {
    sku: function() {
      this.sku = this.sku.toUpperCase().replace(/[^A-Za-z0-9-]/g, "")
    },
    supplierSKU: function() {
      this.supplierSKU = this.supplierSKU.toUpperCase().replace(/[^A-Za-z0-9-]/g, "")
    }
  },
  mounted: function() {
    this.$store.dispatch("ProductSuppliers/setProductSuppliers")
    this.$store.dispatch("ProductCategories/setProductCategories")
    Products.getOne(this.uid).then(this.setProduct)
  }
}
</script>
<style scoped>
  .pageSection {
    margin-bottom: 2em;
  }

  .box {
    border: 1px solid #e0e0e0;
    height: 100%;
    width: 100%;
  }



  .supplierInfo {
  }
</style>
