<template>
  <span class="price">
    {{_price}}
  </span>
</template>

<script>
export default {
  name: 'Price',
  props: {
    price: Number
  },
  methods: {
    formatMoney(num) {
      return "$" + Number(num).toFixed(2).toLocaleString()
    }
  },
  computed: {
    _price: function() {
      return this.formatMoney(this.price)  
    },
  }
}
</script>

