<template>
  <div class="productTable">
    <table class="table is-fullwidth">
      <thead>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
      </thead>
      <tbody>
        <tr v-for="product in products" :key="product.uid">
          <td>
            <div>
              <label class="checkbox">
                <input type="checkbox">
              </label>  
            </div>
          </td>
          <td>
            <div class="productImage">
              <img :src="product.imageURL || 'https://static.thenounproject.com/png/192321-200.png'"/>
            </div>
          </td>
          <td>
            <div>
              <router-link :to="'/dashboard/products/' + product.uid">{{product.name}}</router-link>
            </div>
          </td>
          <td>
            <div>
              {{product.sku || "-"}}
            </div>
          </td>
          <td>
            <div>
              <Price :price="product.listPrice"></Price>
            </div>
          </td>
          <td>
            <div>
              <i>{{product.variations.length}} variations</i>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Price from "@/components/dashboard/products/Price"

export default {
  name: 'Product Table',
  props: {
    products: Array
  },
  components: {
    Price
  }
}
</script>

<style scoped>

  td {
    height: 100%;
  }

  td div {
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
  }

  .productImage {
    height: 50px;
    width: 50px;
    object-fit: contain;
  }
</style>
