<template>
  <div class="newProductSupplierModal">
    <div :class="'modal ' + (open? 'is-active' : '' )">
      <div class="modal-background"></div>
      <div class="modal-content">
        <header class="modal-card-head">
          <p class="modal-card-title">New Product Supplier</p>
          <button class="delete" aria-label="close" @click="close"></button>
        </header>
        <section class="modal-card-body">
          <div class="field">
            <label class="label">
              Supplier Name
            </label>
            <input type="text" class="input" required v-model="name">
          </div>
        </section>
        <footer class="modal-card-foot">
          <button class="button is-success" @click="createSupplier" :disabled="name.length <= 0">Create</button>
          <button class="button" @click="close">Cancel</button>
        </footer>
      </div>
      <button class="modal-close is-large" aria-label="close"></button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'New Product Supplier',
  props: {
    open: Boolean
  },
  data() {
    return {
      name: ""
    }
  },
  methods: {
    createSupplier() {
      this.$store.dispatch("ProductSuppliers/createProductSupplier", {name: this.name}).then(this.close)
    },

    close(res) {
      if(res) {
        console.log("onClose", res)
      } 

      this.$emit("onClose", res)
    }
  }
}
</script>

