<template>
  <div class="newProductAttributeModal">
    <div :class="'modal ' + (open? 'is-active' : '' )">
      <div class="modal-background"></div>
      <div class="modal-content">
        <header class="modal-card-head">
          <p class="modal-card-title">New Product Attribute</p>
          <button class="delete" aria-label="close" @click="close"></button>
        </header>
        <section class="modal-card-body">
          <div class="field">
            <label class="label">
              Name
            </label>
            <input type="text" class="input" required v-model="name">
          </div>
          <div class="field">
            <label class="label">
              Value
            </label>
            <input type="text" class="input" required v-model="value">
          </div>
        </section>
        <footer class="modal-card-foot">
          <button class="button is-success" @click="createAttribute" :disabled="name.length <= 0 || value.length <= 0">Create</button>
          <button class="button" @click="close">Cancel</button>
        </footer>
      </div>
      <button class="modal-close is-large" aria-label="close"></button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'New Product Attribute',
  props: {
    open: Boolean
  },
  data() {
    return {
      name: "",
      value: ""
    }
  },
  methods: {
    createAttribute() {
      this.$emit('onSubmit', {name: this.name, value: this.value})
      this.name = ""
      this.value = ""
    },

    close(res) {
      if(res) {
        console.log("onClose", res)
      } 

      this.$emit("onClose", res)
    }
  }
}
</script>

