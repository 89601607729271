<template>
  <div class="newProduct">
    <h1 class="title">
      Create Product
    </h1>
    <div class="pageSection columns">
      <div class="column is-full">
        <div class="box columns">
  
          <div class="field column">
            <label class="label">Name</label>
            <input type="text" class="input" v-model="name">
          </div>
 
          <div class="field column">
            <label class="label">SKU</label>
            <input type="text" class="input" v-model="sku">
          </div>
 
          <div class="field column">
            <label class="label">List Price</label>
            <input type="text" class="input" v-model="listPrice">
          </div>

          <div class="field column">
            <label class="label">Category</label>
            <div class="select is-fullwidth">
              <select v-model="category">
                <option :key="category.uid" v-for="category in _categories" :value="category.uid">{{category.name}}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="pageSection columns">
      <div class="column is-one-quarter">
        <div class="box">
          <div class="is-flex is-align-items-center">
            <h2 class="is-size-5">
              Attributes
            </h2>
            <button class="button is-inverted is-info" style="margin-left: auto; margin-top: 5px;" @click="newAttributeModalOpen = true">Add Attribute</button>
          </div>

          <span v-if="attributes.length == 0">This product has no attributes</span>
          <table class="table is-fullwidth">
            <thead>
              <th></th>
              <th></th>
            </thead>
            <tbody>
              <tr v-for="(attribute, i) in attributes" :key="i">
                <td>{{attribute.name}}</td>
                <td>{{attribute.value}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="column is-one-quarter">
        <div class="box">
          <h2 class="is-size-5" style="margin-bottom: 10px;">
            Inventory
          </h2>
    
          <div class="field">
            <label class="label">
              Aisle
            </label>
            <input class="input" type="text" v-model="aisle">
          </div>
          <div class="field">
            <label class="label">
              Bin
            </label>
            <input class="input" type="text" v-model="bin">
          </div>
          <div class="field">
            <label class="label">
              Quantity
            </label>
            <input class="input" type="text" v-model="quantity_on_hand">
          </div>
        </div>
      </div>

      <div class="column is-half">
        <div class="box">
        <div class="columns is-multiline  supplierInfo">
          <div class="column is-full">
            <h2 class="is-size-5">
              Supplier Information
            </h2>
          </div>
          <div class="column">
            <div class="field">
              <div class="is-flex is-align-items-center">
                <label class="label">Supplier </label>
                <small class="is-info" style="margin-left: auto; margin-bottom: 0.5em;" @click="newSupplierModalOpen = true">
                  <a href="#" >
                  Add Supplier
                  </a>
                </small>
              </div>
              <div class="select is-fullwidth">
                <select v-model="supplier">
                  <option disabled value="" style="display: none;"></option>
                  <option :value="supplier.uid" v-for="supplier in suppliers" v-html="supplier.name" :key="supplier.uid"></option>
                </select>
              </div>
            </div>
            <div class="field">
              <label class="label">Cost</label>
              <input type="text" class="input" v-model="cost">
            </div>
            
          </div>
          <div class="column">
            <div class="field">
              <label class="label">Supplier SKU</label>
              <input type="text" class="input" v-model="supplierSKU">
            </div>


            <div class="field">
              <label class="label">Lead Time</label>
              <input type="text" class="input" v-model="supplier_lead_time">
            </div>
          </div>
          <div class="column">
            <div class="field is-flex is-flex-direction-column">
              <label class="label">Additional Information</label>
              <label class="checkbox">
                <input disabled v-model="supplier_discontinued" type="checkbox">
                Discontinued
              </label>
              <label class="checkbox">
                <input v-model="supplier_out_of_stock" type="checkbox">
                Out of Stock
              </label>
            </div>
          </div>
        </div>
        </div>
      </div>

    </div>


    

    <div class="columns">
      <div class="column is-flex is-justify-content-end">
        <button class="button is-primary" @click="createProduct" :disabled="!canCreate">Create Product</button>
      </div>
    </div>
    <NewSupplierModal :open="newSupplierModalOpen" @onClose="closeNewSupplierModal"></NewSupplierModal>
    <NewAttributeModal :open="newAttributeModalOpen" @onSubmit="addAttribute" @onClose="closeNewAttributeModal"></NewAttributeModal>

  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import NewSupplierModal from '@/components/dashboard/products/NewProductSupplierModal'
import NewAttributeModal from '@/components/dashboard/products/NewProductAttributeModal'

export default {
  name: 'New Product',
  components: {
    NewSupplierModal,
    NewAttributeModal
  },
  data() {
    return {
      attrName: "",
      attrValue: "",
      attributes: [],
      newSupplierModalOpen: false,
      newAttributeModalOpen: false,
      name: "",
      sku: "",
      listPrice: "",
      supplierSKU: "",
      cost: "",
      supplier: "",
      supplier_out_of_stock: false,
      supplier_discontinued: false,
      supplier_lead_time: "",
      aisle: "",
      bin: "",
      quantity_on_hand: "",
      category: "",
      tags: [],
      imageURL: "",
      listingUID: "",
      parentUID: "",
      variations: []
    }
  },
  methods: {
    closeNewAttributeModal() {
      this.newAttributeModalOpen = false
    },
    addAttribute(data) {
      var name = data.name
      var value = data.value

      this.attributes.push({
        name: name,
        value: value
      })

      this.newAttributeModalOpen = false
      
    },
    closeNewSupplierModal(res) {
      if(res && res.uid) {
        this.supplier = res.uid
      }
      console.log(res)
      this.newSupplierModalOpen = false
    },
    createProduct() {
      this.$store.dispatch("Products/createProduct", {
        name: this.name,
        sku: this.sku,
        listPrice: this.listPrice,
        supplierSKU: this.supplierSKU,
        cost: this.cost,
        supplier: this.supplier,
        imageURL: this.imageURL,
        listingUID: this.listingUID,
        parentUID: this.parentUID,
        variations: this.variations,
        supplier_out_of_stock: this.supplier_out_of_stock,
        supplier_discontinued: this.supplier_discontinued,
        aisle: this.aisle,
        bin: this.bin,
        quantity_on_hand: this.quantity_on_hand,
        attributes: this.attributes,
        supplier_lead_time: this.supplier_lead_time,
        category: this.category,
        tags: ""
      }).then(this.redirect)
    },
    redirect(product) {
      if(product.uid) {
        this.$router.push("/dashboard/products/" + product.uid)
      } else {
        this.$toast.create("Unable to create product")
      }
    }
  },
  computed: {
    canCreate() {
      return this.name.length > 4 && this.sku.length > 3
    },
    suppliers() {
      if(!this._suppliers) {
        return []
      }
      return this._suppliers.sort((a, b) => { a.name.localeCompare(b.name) })
    },
    ...mapGetters({
      _suppliers: "ProductSuppliers/getProductSuppliers",
      _categories: "ProductCategories/getProductCategories"
    })
  },
  watch: {
    sku: function() {
      this.sku = this.sku.toUpperCase().replace(/[^A-Za-z0-9-]/g, "")
    },
    supplierSKU: function() {
      this.supplierSKU = this.supplierSKU.toUpperCase().replace(/[^A-Za-z0-9-]/g, "")
    }
  },
  mounted: function() {
    this.$store.dispatch("ProductSuppliers/setProductSuppliers")
    this.$store.dispatch("ProductCategories/setProductCategories")

  }
}
</script>
<style scoped>
  .pageSection {
    margin-bottom: 2em;
  }

  .box {
    border: 1px solid #e0e0e0;
    height: 100%;
    width: 100%;
  }



  .supplierInfo {
  }
</style>
